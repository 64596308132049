import React from "react";
import { Link } from "react-router-dom";

// portfolio items
const portfolio_data = [
  {
    id:1,
    img: "../assets/img/clinet/client-1.jpg",
    uri:"http://gemsimmigrationservices.com/"
  },
  {
    id:2,
    img: "../assets/img/clinet/client-2.jpg",
    uri:"http://gemsmortgageconsultants.com/"
  },
  {
    id:3,
    img:'/assets/img/clinet/client-3.jpg',
    img: "../assets/img/clinet/client-3.jpg",
    uri:"http://gemspropertiesllc.com/"
  },
  {
    id:4,
    img:'/assets/img/clinet/client-4.jpg',
    img: "../assets/img/clinet/client-4.jpg",
    uri:"http://gemstechnologiesuae.com/"
  }
]

const PortfolioItems = () => {
  return (
    <>
      <div className="project-page-list pt-140 pb-90">
        <div className="container">
          <div className="row">
            {portfolio_data.map((item) => (
            <div key={item.id} className="col-lg-6 col-md-6 col-12">
              <div className="pj-list-item mb-50">
                <div className="pj-list__img">
                <a target="_blank" href={item.uri}><img src={item.img} alt="" /></a>
                </div>
                {/* <span>
                  <a href="#">{item.tag}</a>
                </span> */}
                <h4 className="pj-list__title">
                  {/* <Link to="/portfolio-details">{item.title}</Link> */}
                </h4>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioItems;
