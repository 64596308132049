import React from "react";
import Brands from "../../common/Brands/Brands";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
// import AboutFeature from "./AboutFeature";
// import Testimonial from "../../common/Testimonial/Testimonial";
// import Services from "../../HomeTwo/Services";

const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />
      {/* <Services />
      <AboutFeature />
      <Testimonial /> */}
      <Brands />
      <FooterTwo />
    </>
  );
};

export default About;
