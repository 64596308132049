import React from "react";
import Menu from "../common/Menu/Menu";
import Feature from "./Feature";
import Brands from "../common/Brands/Brands";
import FullScreenBtn from "../common/FullScreenBtn";
import TopAbout from "./TopAbout";
import TopCtaArea from "./TopCtaArea";
import HomeSlider from "./HomeSlider";
import FooterTwo from "../common/Footer/FooterTwo";
// import Gallery from "./Gallery";
// import Team from "../common/Team/Team";
// import Testimonial from "../common/Testimonial/Testimonial";
// import TopFooter from "../common/TopFooter/TopFooter";
// import LastedBlog from "./LastedBlog";
const Home = () => {
    return (
        <>
            <Menu header_style_2={true} />
            <HomeSlider />
            <TopAbout />
            <Brands />
            <TopCtaArea />
            {/* <Gallery /> */}
            {/* <Team /> */}
            <Feature />
            {/* <Testimonial />
            <LastedBlog /> */}
            <FullScreenBtn />
            {/* <TopFooter /> */}
            <FooterTwo />
        </>
    );
};

export default Home;
