import React from 'react';

const FooterTwo = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-1 pb-30">
                      <div className="footer-logo mb-30">
                        <a href="/"><img src="/assets/img/logo/logo-black.png" alt="" /></a>
                      </div>
                      <p className='text-justify'>Gems Group has established itself as one of leading financial advisory house, offering various financial solutions to its Institutional, corporate and individual clients.</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Quick Links</h3>
                      <ul>
                        <li>- <a href="/">Home</a></li>
                        <li>- <a href="/about">About Us</a></li>
                        <li>- <a href="/brands">Our Brands</a></li>
                        <li>- <a href="/contact">Contact Us</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-4  pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Brands</h3>
                      <ul>
                        <li>- <a href="http://gemsimmigrationservices.com/" target='_blank'>Gems Immigration Consultants</a></li>
                        <li>- <a href="http://gemsmortgageconsultants.com/" target='_blank'>Gems Mortgage Consultancy</a></li>
                        <li>- <a href="http://gemspropertiesllc.com/" target='_blank'>Gems Properties</a></li>
                        <li>- <a href="http://gemstechnologiesuae.com/" target='_blank'>Gems Technologies</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-3 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Get In Touch</h3>
                      <ul>
                        <li><a href="https://goo.gl/maps/gmG31G1B71XkzJje7" target='_blank'> <i className="fa fa-map-marker"></i> Office # 214, Al Qayadah Building, Abu hail, Dubai - U.A.E. </a></li>
                        <li><a href="#."> <i className="fa fa-box"></i> P.O. Box 80808 </a></li>
                        <li><a href="tel:+97142346599"> <i className="fa fa-phone-alt"></i> (+971) 4 234 6599</a></li>
                        <li><a href="mailto:info@gemsgroupuae.com"> <i className="fa fa-envelope"></i> info@gemsgroupuae.com</a></li>
                        <li><span> <i className="fa fa-clock"></i> Working Hours: 09:00 am to 06:00 pm from Monday to Saturday</span></li>
                        <li><span> Sunday : Closed</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="tp-copyrigh-text mb-30">
                <span>© {new Date().getFullYear()} Gems Group. All Rights Reserved. Developed with <i className='fa fa-heart text-danger'></i> By <a href="https://www.visionsoftwares.com" target='_blank'>Vision Technologies</a></span>
                {/* Alston Rebello */}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="tp-footer-social-icon mb-30 text-md-end">
                <ul>
                  <li><a href="https://www.facebook.com/Gemsgroupdubai" target="_blank"><i className="fab fa-facebook"></i></a></li>
                  <li><a href="https://www.instagram.com/gemsgroupdubai/" target="_blank"><i className="fab fa-instagram ms-2"></i></a></li>
                  <li><a href="https://www.linkedin.com/in/gemsgroup-uae-a71547284/" target="_blank"><i className="fab fa-linkedin-in ms-2"></i></a></li>
                  <li><a href="https://www.tiktok.com/@gemsgroupuae?lang=en" target="_blank"><i className="fab fa-tiktok ms-2"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterTwo;